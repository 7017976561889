/* 폰트 */
@import url('https://hangeul.pstatic.net/hangeul_static/css/nanum-barun-gothic.css');
/*테일윈드*/
@tailwind base;
@tailwind components;
@tailwind utilities;


html, body, #root {
    height: 100%;
    margin: 0;
    background-color: black;
}

body {
    margin: 0;
    font-family: 'NanumBarunGothic', serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer components {
    /* 버튼 CSS */
    .button-style {
        @apply inline-block rounded bg-slate-800 px-6 py-2 leading-normal text-white shadow-md;
    }

    /* 금액 키패드 버튼 */
    .keypad-background-style {
        background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 6%, rgba(255, 255, 255, 0.1) 7.5%),
        repeating-linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 4%, rgba(0, 0, 0, 0.03) 4.5%),
        repeating-linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 1.2%, rgba(255, 255, 255, 0.15) 2.2%),
        linear-gradient(180deg, hsl(0, 0%, 78%) 0%, hsl(0, 0%, 90%) 47%, hsl(0, 0%, 78%) 53%, hsl(0, 0%, 72%) 100%);
    }

    .keypad-button-style {
        background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 6%, rgba(255, 255, 255, 0.2) 7.5%),
        repeating-linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 4%, rgba(0, 0, 0, 0.02) 4.5%),
        repeating-linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 1.2%, rgba(255, 255, 255, 0.2) 2.2%),
        linear-gradient(180deg, hsl(0, 0%, 90%) 0%, hsl(0, 0%, 96%) 47%, hsl(0, 0%, 90%) 53%, hsl(0, 0%, 95%) 100%);
    }

    /* 게임카드 - 경기 선택 버튼 */
    .game-card-btn-custom {
        width: 4.2rem;
        height: 2.5rem;
        border: 1px solid #c8c8c8;
        box-shadow: inset 1px 1px 1px 0px rgba(255, 255, 255, 0.76),
        4px 4px 10px 0px rgba(0, 0, 0, 0.05),
        2px 2px 3px 0px rgba(0, 0, 0, 0.05);
        border-radius: 0.5rem;
        margin-left: 0.5rem;
        margin-top: 0.25rem;
        font-weight: 600;
        font-size: small;
        background: rgb(255, 255, 255);
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.85) 0%, rgb(239, 238, 238) 100%);
        transition: all 0.3s ease;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
    }

    /* 게임카드 - 경기번호 */
    .game-card-btn-custom.game-number {
        font-size: medium;
        box-shadow: none;
        border: 1px solid rgba(10, 14, 23, 0.73);
        background: rgba(21, 25, 44, 0.97);
        color: rgba(229, 231, 235, 1);
    }
    body.light-mode .game-card-btn-custom.game-number {
        font-size: medium;
        box-shadow: none;
        border: 1px solid rgba(28, 101, 167, 0.97);
        background: rgba(28, 101, 167, 0.97);
        color: rgb(255, 255, 255);
    }

    /* 게임카드 - 선택했을 때 버튼 */
    .game-card-btn-custom.selected-state {
        border: 1px solid #e5cb36;
        background: linear-gradient(0deg, rgba(236, 219, 30, 0.6) 0%, rgb(255, 213, 0) 100%);
    }

    /* 게임카드 - 비활성화 버튼 */
    .game-card-btn-custom.disabled-state {
        border: 1px solid #979797;
        background: linear-gradient(0deg, rgba(51, 51, 51, 0.91) 0%, rgb(114, 114, 114) 100%);
        color: white;
        cursor: not-allowed;
        pointer-events: none;
    }
    body.light-mode .game-card-btn-custom.disabled-state {
        border: 1px solid #328bc1;
        background: linear-gradient(0deg, rgba(23, 79, 132, 0.97) 0%, rgba(90, 140, 186, 0.97) 100%);
        color: white;
        cursor: not-allowed;
        pointer-events: none;
    }

    /* 게임카드 잠금 버튼 */
    .game-card-btn-custom.lock-state {
        background: rgba(5, 5, 5, 0.3);
        cursor: not-allowed;
    }

    /* 행복픽 금액 버튼 */
    .happy-pick-print-btn {
        min-width: 130px;
        height: 40px;
        color: #fff;
        padding: 5px 10px;
        font-weight: bold;
        cursor: pointer;
        display: inline-block;
        border-radius: 5px;
        border: none;
        box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5), 7px 7px 20px 0px rgba(0, 0, 0, .1), 4px 4px 5px 0px rgba(0, 0, 0, .1);
        background: rgb(24, 34, 56);
        font-size: small;
    }
}


/* 스크롤바 커스텀 */
*::-webkit-scrollbar {
    width: 10px;
    height: 13px;
}

*::-webkit-scrollbar-track {
    /*background: #111827;*/
    /*border-radius: 5px;*/
}

*::-webkit-scrollbar-thumb {
    background-color: rgb(222, 203, 127);
    border-radius: 13px;
    /*border: 3px solid #A5B4FC;*/
}
body.light-mode *::-webkit-scrollbar-thumb {
    background-color: rgb(94, 160, 197);
    border-radius: 13px;
    /*border: 3px solid #A5B4FC;*/
}

